<div class="custom-loop-page">
    <div *ngIf="choseTemplate || choseGeneralCategories || choseSpecialCategories || chooseTiming || chooseReview || chooseCongrats" class="page-header custom-page-header justify-content-between">
      <h2 class="page-header-title" *ngIf="choseTemplate">{{'custom_poll_main_title'|translate}}</h2>
      <h2 class="page-header-title" *ngIf="!choseTemplate">{{customPollMainTitle}}</h2>
      <app-breadcrumb [currentStep]="currentStep" (jumpToStep)="setCustomPollStepFromBreadCrumb($event)" *ngIf="!choseTemplate && !chooseCongrats"></app-breadcrumb>
    </div>
    <div *ngIf="choseTemplate" class="choose-template-container">
        <app-choose-template [workspaceTemplates]="costumPollTemplates" 
          [workspaceCustomLoops]="customPolls"
          [customPollResponseRates]="customPollResponseRate" 
          (templateEvent)="createCustomPoll($event);choseTemplate = false;" 
          (resumeDraftEvent)="resumeDraft($event)"></app-choose-template>
    </div>
    <div class="categories-container">
      <app-general-categories *ngIf="choseGeneralCategories && currentStep === '1'"
        [generalCategories]="customPollGeneralCategories"
        [generalStatements]="generalStatements.asObservable()" 
        (generalCategoriesEvent)="createCustomPollGeneralCategories($event)"
        (getStatementsEvent)="getGeneralCategoriesStatements($event)"
        (saveAndFinishLater)="saveAndFinishLater($event)"></app-general-categories>
      <app-special-categories *ngIf="choseSpecialCategories && currentStep === '2'" 
        [specialCategories]="customPollSpecialCategories"
        [specialStatements]="specialStatements.asObservable()"
        [selectedTeamsForModal]="selectedSpecialCategoryTeams.asObservable()"
        (specialCategoriesEvent)="createCustomPollSpecialCategories($event)"
        (getStatementsEvent)="getSpecialCategoriesStatements($event)"
        (getCategoryTeams)="getSpecialCategorySelectedTeams($event)"
        (stepBack)="goBack($event)"
        (saveAndFinishLater)="saveAndFinishLater($event)"></app-special-categories>
      <app-review-custom-poll *ngIf="chooseReview && currentStep === '3'" 
        [reviewCustomPollTeams]="customPollReviewTeams.asObservable()" 
        (endReview)="endReviewAndGoToTiming($event)"
        (stepBack)="goBack($event)"
        (saveAndFinishLater)="saveAndFinishLater($event)"></app-review-custom-poll>
      <app-timing *ngIf="chooseTiming && currentStep === '4'" 
        [timingDetails]="customPollTimingDetails" 
        (timingDetailsEvent)="createTimingDetailsStep($event)"
        (stepBack)="goBack($event)"
        (saveAndFinishLater)="saveAndFinishLater($event)"></app-timing>
    </div>
    <div class="congrats-container">
      <app-congrats *ngIf="chooseCongrats" [details]="congratsDetails"></app-congrats>
    </div>
</div>