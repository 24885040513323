<div class="main-container">
    <img src="../../../../../assets/images/success_icons_apv.png">
    <h2 style="font-size: 2.4rem; margin: 4.1rem 0 1.6rem 0;">{{'custom_poll_congrats_title' | translate}}</h2>
    <span [ngStyle]="details.date.subtitle === 2 ? {'width':'45rem'}:{'width':'33rem'}" style="text-align: center;font-size: 1.6rem; margin: 4rem 0 6rem 0;">
        {{('custom_poll_congrats_subtitle_'+details.date.subtitle | translate: {x: details?.date.daysRemaining})}}</span>
    <button class="general-categories-next"
            routerLink="/" 
            pButton type="button" 
            label="{{'custom_poll_congrats_button' | translate}}"
            (click)="end()"></button>
</div>