import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-congrats',
  templateUrl: './congrats.component.html',
  styleUrls: ['./congrats.component.scss']
})
export class CongratsComponent implements OnInit {

  @Input() details: any;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  end() {
    this.router.navigate(['/']);
  }

}
