import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataManipulationService } from 'src/app/services/data-manipulation.service';

@Component({
  selector: 'app-choose-template',
  templateUrl: './choose-template.component.html',
  styleUrls: ['./choose-template.component.scss']
})
export class ChooseTemplateComponent implements OnInit {
   @Input() workspaceTemplates: any;
   @Input() workspaceCustomLoops: any;
   @Input() customPollResponseRates: any;
   
   @Output() templateEvent = new EventEmitter<any>();

   @Output() resumeDraftEvent = new EventEmitter<any>();

  templateMock = {
    title: 'APV',
    categories: [
      {
        title: this.translate.instant('custom_poll_temp_categ_general'),
        color: '#F6EDFF'
      },
      {
        title: this.translate.instant('custom_poll_temp_categ_productivity'),
        color: '#FFEDFC'
      },
      {
        title: this.translate.instant('custom_poll_temp_categ_leader'),
        color: '#F9F8ED'
      },
      {
        title: this.translate.instant('custom_poll_temp_categ_health'),
        color: '#EDFAFB'
      },
      {
        title: this.translate.instant('custom_poll_temp_categ_ergonomics'),
        color: '#EDF0F9'
      },
      {
        title: this.translate.instant('custom_poll_temp_categ_stress'),
        color: '#F9EDF0'
      },
    ]
  };

  customPolls: any[] = [];
  customPollAssignments: any[] = [];

  userPriority: any;

  constructor(private translate: TranslateService, public data: DataManipulationService, public router: Router) { }

  ngOnInit(): void {
    this.customPolls = this.workspaceCustomLoops;
    this.userPriority = this.data.userRole.role.workspacePriority;
  }

  choseTemplate(templateUuid: string) {
    this.templateEvent.emit(templateUuid);
  }

  getRemainingDays(start: any, end: any) {
    const oneDay = 24 * 60 * 60 * 1000;
    const endDate = new Date(end);
    const today = new Date();
    let startDate;
    if (!start) {
      startDate = today;
    } else {
      startDate = new Date(start).getTime() > today.getTime() ? start : today;
    }
    if(start !== null || end !== null) {
      return Math.round(Math.abs((endDate.getTime() - startDate.getTime()) / oneDay));
    } else {
      return 0;
    }
  }

  getPollResponseRate(poll: any) {
    let responseRate = 0;
    
    if (this.customPollResponseRates.length) {
      this.customPollResponseRates.forEach((assignment: any) => {
        if (poll.uuid === assignment.uuid) {
          poll.reportingDetails = assignment.reportingDetails;
          responseRate = assignment.responseRate;
        }
      });
    }
    return responseRate;
  }

  resumeDraft(poll: any) {
    this.resumeDraftEvent.emit(poll);
  }

  goToPollReporting(poll: any) {
    this.data.setCustomPoll(poll);
    this.router.navigate(['/reporting/'+poll.uuid]);
  }

}
