<div class="main-container">
    <div *ngIf="customPolls.length" class="active-polls">
        <div class="active-custom-poll" *ngFor="let poll of customPolls">
            <span style="color: #858DA2; font-size: 1.2rem; margin-bottom: .5rem;">{{poll.status | titlecase}} {{'custom_poll_temp_loop_assessment' | translate}}</span>
            <div class="status-and-response-rate-container">
                <h2 style="font-size: 2.4rem;margin-bottom: 1rem;">{{poll.name}}</h2>
                <div class="status-and-response-rate">
                    <div class="response">
                        <img class="response-rate-image" src="../../../../../assets/images/apv.png">
                        <div class="response-rate-details">
                            <span class="response-rate-detail-title"><b>{{'custom_poll_temp_loop_response_rate' | translate}}</b></span>
                            <h2 style="font-size: 4.6rem; margin-bottom: 0;">{{getPollResponseRate(poll)}} %</h2>
                            <span class="reminder"><i class="bi bi-envelope"></i>{{'custom_poll_temp_send_reminder' | translate}}</span>
                        </div>
                    </div>
                    <div class="status">
                        <span class="status-title">Status: 
                            <i [ngStyle]="poll.status === ('custom_poll_temp_loop_running' | translate) ? {'color':'#16C285'}:{}" class="bi bi-dot"></i> 
                            <b [ngStyle]="poll.status === ('custom_poll_temp_loop_running' | translate) ? {'color':'#16C285'}:{}">{{poll.status}}</b>
                        </span>
                        <span *ngIf="poll.status === ('custom_poll_temp_loop_completed' | translate) " class="status-title">{{'custom_poll_temp_loop_days_completed' | translate}} 
                            <b style="color: black; margin-left: .4rem;">{{poll.endDate?.date | date:'dd.MM.yyyy'}}</b>
                        </span>
                        <span *ngIf="poll.status === ('custom_poll_temp_loop_running' | translate) " class="status-title">{{'custom_poll_temp_loop_days_until' | translate}} 
                            <b style="color: black; margin-left: .4rem;">{{getRemainingDays(poll.startDate?.date, poll.endDate?.date) + ('custom_poll_temp_loop_days' | translate)}}</b>
                        </span>
                        <span *ngIf="poll.status === ('custom_poll_temp_loop_scheduled' | translate) " class="status-title">{{'custom_poll_temp_loop_days_scheduled' | translate}} 
                            <b style="color: black; margin-left: .4rem;">{{getRemainingDays(null, poll.startDate?.date) + ('custom_poll_temp_loop_days' | translate)}}</b>
                        </span>
                        <span *ngIf="poll.status === ('custom_poll_temp_loop_draft' | translate) " class="status-title">{{'custom_poll_temp_loop_days_until' | translate}} 
                            <b style="color: black; margin-left: .4rem;">{{getRemainingDays(poll.startDate?.date, poll.endDate?.date) + ('custom_poll_temp_loop_days' | translate)}}</b>
                        </span>
                        <!-- <div class="see-report">
                            {{'custom_poll_temp_loop_see_report' | translate}}
                        </div> -->
                        <button *ngIf="poll.status === ('custom_poll_temp_loop_completed' | translate)"
                                (click)="goToPollReporting(poll)" 
                                style="margin: 1.6rem 0 0 0;padding: 1rem 3rem;max-width: 19.1rem;" 
                                class="template-button">
                                {{'custom_poll_temp_loop_see_report' | translate}}</button>
                        <button *ngIf="poll.status === ('custom_poll_temp_loop_scheduled' | translate)"
                                style="margin: 1.6rem 0 0 0;padding: 1rem 3rem;max-width: 19.1rem;" 
                                class="template-button scheduled-btn-dsb"
                                [disabled]="true">
                                {{'custom_poll_temp_loop_see_report' | translate}}</button>
                        <button *ngIf="poll.status === ('custom_poll_temp_loop_running' | translate)" 
                                style="margin: 1.6rem 0 0 0;padding: 1rem 3rem;" 
                                class="template-button scheduled-btn-dsb"
                                (click)="goToPollReporting(poll)"
                                [disabled]="true"
                                pTooltip="{{'custom_poll_temp_loop_running_tooltip' | translate}}" tooltipPosition="bottom" tooltipStyleClass="response-distribution-tooltip">
                                {{'custom_poll_temp_loop_see_report' | translate}}</button>
                        <button *ngIf="poll.status === ('custom_poll_temp_loop_draft' | translate)" 
                            style="margin: 1.6rem 0 0 0; padding: 1rem 3rem;" 
                            class="template-button"
                            (click)="resumeDraft(poll)">
                                {{'custom_poll_temp_loop_resume_button' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [ngStyle]="!customPolls.length ? {'flex-direction':'row','align-items':'flex-start', 'gap':'5rem'}:{}" class="templates-to-choose">
        <div class="template-container" *ngFor="let template of workspaceTemplates">
            <h2 class="template-title">{{template.name}}</h2>
            <span class="template-text">{{template.description}}</span>
            <img class="template-image" src="../../../../../assets/images/test-apv.png">
            <div class="template-categories-container">
                <div class="category" *ngFor="let categ of templateMock.categories">
                    <span class="" [ngStyle]="{'background-color': categ.color}">{{categ.title}}</span>
                </div>
            </div>
            <span class="many-more">{{'custom_poll_temp_many_more' | translate}}</span>
            <button [ngClass]="userPriority < 20 ? 'get-started-btn-dsb':''"
                class="template-button"
                pTooltip="{{'left_menu_11_tooltip' | translate}}" tooltipPosition="bottom" tooltipStyleClass="response-distribution-tooltip"
                [tooltipDisabled]="userPriority >= 20" 
                (click)="choseTemplate(template.uuid)"
                [disabled]="(userPriority < 20)">
                {{'custom_poll_temp_get_started_btn' | translate}}<i class="bi bi-arrow-right"></i></button>
        </div>
        <div class="contact-us-container">
            <span class="contact-us">{{'custom_poll_temp_contact_us' | translate}}</span>
            <h2 class="contact-us-title">{{'custom_poll_temp_contact_us_title' | translate}}</h2>
            <span class="contact-us-text">{{'custom_poll_temp_contact_us_text1' | translate}}</span>
            <span [ngStyle]="{'margin-top':'1.2rem'}" class="contact-us-text" [innerHTML]="('custom_poll_temp_contact_us_text2' | translate)"></span>
        </div>
    </div>
</div>